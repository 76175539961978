import React, { useState, useEffect, ChangeEvent } from 'react'
import { Input, InputLabel, MenuItem, FormControl, ListItemText, Select, Checkbox } from '@material-ui/core'

interface Props {
  companies: Companies
  updateCompanySelection: (selectedCompanyIds: Array<string>) => void
}

interface handleChangeEvent extends Omit<HTMLInputElement, 'value'> {
  value: Array<string>
}

const CompanySelect = ({
  companies,
  updateCompanySelection,
}: Props) => {
  const [selectedCompanies, setSelectedCompanies] = useState<Array<string>>([])

  const handleChange = (event: ChangeEvent<handleChangeEvent>) => {
    setSelectedCompanies(event.target.value)

    const selectedCompanyIds = event.target.value
      .map((companyName: string) => companies.find((company) => company.name === companyName)?.id)
      .filter(value => value)

    updateCompanySelection(selectedCompanyIds as (string)[])
  }

  const renderValue = (value: Array<string>) => value.join(', ')

  useEffect(() => {
    if (!companies || companies.length < 1) return
    handleChange({ target: { value: [companies[0].name] } } as ChangeEvent<handleChangeEvent>)
  }, [])

  if (!companies) return null

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel>Bedrijven</InputLabel>
      <Select
        multiple
        fullWidth
        value={selectedCompanies}
        onChange={(e) => handleChange(e as ChangeEvent<handleChangeEvent>)}
        input={<Input />}
        renderValue={selected => renderValue(selected as Array<string>)}
      >
        {companies.map((company) => (
          <MenuItem dense={true} key={company.id} value={company.name}>
            <Checkbox size="small" checked={selectedCompanies.indexOf(company.name) > -1} />
            <ListItemText primary={company.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CompanySelect
