import { addMonths, format, startOfYear } from 'date-fns'
import Handsontable from 'handsontable'
import { profitLossData } from './index'

type profitLossDataTypes =
  'grossProfit' | 'personnelCosts' | 'advertisingSellingCosts' | 'operatingExpenses' | 'operatingResult' |
  'internalCosts' | 'partnerFee' | 'additionalCosts' | 'operatingResultAfterTax'

const profitLossDataRowOrder: Array<profitLossDataTypes>= [
  'grossProfit',
  'internalCosts',
  'partnerFee',
  'personnelCosts',
  'advertisingSellingCosts',
  'additionalCosts',
  'operatingExpenses',
  'operatingResult',
  'operatingResultAfterTax',
]

export const findJournalPostIndex = (postName: string) => profitLossDataRowOrder.findIndex((row) => row === postName)

export const generateTableSettings = (
  profitLossData: profitLossData,
  year: Date
): Handsontable.GridSettings => {
  const journalPostsTitle = 'Posten'
  const headers = [journalPostsTitle]
  const columns = [{ data: journalPostsTitle, readOnly: true }]
  const tableData = {
    grossProfit: { [journalPostsTitle]: 'Bruto winst' },
    internalCosts: { [journalPostsTitle]: 'Interne Fee' },
    partnerFee: { [journalPostsTitle]: 'Partner Fee' },
    personnelCosts: { [journalPostsTitle]: 'Personeelskosten' },
    advertisingSellingCosts: { [journalPostsTitle]: 'Marketing- en Sales -kosten' },
    additionalCosts: { [journalPostsTitle]: 'Overige kosten' },
    operatingExpenses: { [journalPostsTitle]: 'Bedrijfskosten' },
    operatingResult: { [journalPostsTitle]: 'Bedrijfsresultaat' },
    operatingResultAfterTax: { [journalPostsTitle]: 'Bedrijfsresultaat na bel.' },
  }
  let month = 0
  while (month < 12) {
    const date = format(addMonths(startOfYear(year), month), 'yyyy-MM-dd')
    headers.push(date)
    columns.push({ data: date, readOnly: false })
    const profitLossPeriod =
      profitLossData && profitLossData.find((plan) => date === plan.period)
        ? profitLossData.find((plan) => date === plan.period)
        : undefined
    for (const key of profitLossDataRowOrder) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tableData[key][date] = profitLossPeriod && profitLossPeriod[key]
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ? profitLossPeriod[key]
          .toFixed(2)
          .replace(/\.00$/, '')
        : 0
    }
    month++
  }

  return {
    licenseKey: 'non-commercial-and-evaluation',
    data: profitLossDataRowOrder.map((data) => tableData[data]),
    colHeaders: headers,
    columns: columns,
    height: (25 * (profitLossDataRowOrder.length + 1)).toString(),
  }
}
