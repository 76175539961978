import React, { useState } from 'react'
import { RouterProps } from 'react-router-dom'
import { API } from 'aws-amplify'
import { Button, Container, Grid, makeStyles } from '@material-ui/core'
import axios from 'axios';
import { getSignedUploadUrl } from 'src/api/graphql/queries'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  uploadContainer: {
    position: 'relative',
    width: '100%',
    height: '500px',
    maxHeight: 'calc(100vh - 64px)',
    border: '1px dashed #3f51b5',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadInput: {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    opacity: 0,
  },
  buttonGroup: {
    position: 'relative',
    zIndex: 2,
  },
  button: {
    margin: '.6rem',
  }
}))

const UploadPnl = (props: RouterProps) => {
  const [file, setFile] = useState<File | undefined>(undefined)
  const [fileIsUploaded, setFileIsUploaded] = useState<boolean|string>(false)
  const classes = useStyles()

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      setFile(event.target.files[0])
    }
  }

  const uploadFile = async () => {
    if (file !== undefined) {
      const apiResponse = await API.graphql({
        query: getSignedUploadUrl,
        variables: {
          fileName: file.name
            .split(' ')
            .join('')
        }
      }) as {data: {getSignedUploadUrl: {url: string}}};
      await axios
        .put(apiResponse.data.getSignedUploadUrl.url, file)
        .then((res) => {
          res.status === 200 ? setFileIsUploaded(true) : setFileIsUploaded(res.statusText)
        })
    }
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={2} justify="space-between" direction="row">
        <div className={classes.uploadContainer}>
          {fileIsUploaded === false && (
            <>
                <h3>
                    {file ? file.name : 'Drag and drop your file, or click here to select a file'}
                </h3>
                {file && (
                  <div className={classes.buttonGroup}>
                      <Button
                        className={classes.button}
                        onClick={() => setFile(undefined)}
                        variant="outlined"
                        color="primary"
                      >
                          Change file
                      </Button>
                      <Button
                        className={classes.button}
                        onClick={uploadFile}
                        variant="contained"
                        color="primary"
                      >
                          Upload file
                      </Button>
                  </div>
                )}
                <input
                  type="file"
                  name="file"
                  onChange={onChangeFile}
                  className={classes.uploadInput}
                />
            </>
          )}
          {fileIsUploaded === true && (
            <h3>Upload successful</h3>
          )}
          {typeof fileIsUploaded === 'string' && (
            <h3>{fileIsUploaded}</h3>
          )}
        </div>
      </Grid>
    </Container>
  )
}

export default UploadPnl
