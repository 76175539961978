import { useEffect, createRef, useState, RefObject } from 'react'
import Chart, { Chart as IChart } from 'chart.js'
import * as queries from 'src/api/graphql/queries'
import { API } from 'aws-amplify'
import { format } from 'date-fns'
import { Period } from 'src/components/date-picker'
import { calcuculateAxisStepSize } from 'src/utils/calculate-axis-step-size'

type TChart = typeof IChart

let myLineChart: TChart | undefined

interface Props {
  companies: Array<any>
  period: Period
}

export default function ProfitLossChart({
  companies,
  period,
}: Props) {
  const [forecastReports, setForecastReports] = useState()
  const [transforedForecastReports, setTransforedForecastReports] = useState()
  const [yAxisHeight, setYAxisHeight] = useState({ min: 0, max: 0 })
  const chartRef: RefObject<any> = createRef()

  useEffect(() => {
    if (!companies || companies.length < 1 || !period) return

    const getForecastReports = async () => {
      try {
        const apiResponse = await API.graphql({
          query: queries.getForecastKpiReport,
          variables: {
            input: {
              companyIds: companies,
              startDate: format(new Date(period.startDate), 'yyyy-MM-dd'),
              endDate: format(new Date(period.endDate), 'yyyy-MM-dd'),
            },
          },
        })
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setForecastReports(apiResponse.data.getForecastKpiReport)
      } catch (error) {
        console.log(error)
      }
    }
    getForecastReports()
  }, [companies, period])

  useEffect(() => {
    if (!forecastReports) return

    const newTransforedForecastReports: {
      [key: string]: any
    } = {
      targetOperatingResult: [],
      actualOperatingResult: [],
      delta: [],
      periods: [],
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    forecastReports.forEach((forecastReport) => {
      const period = format(new Date(forecastReport.period), 'LLL yy')
      newTransforedForecastReports.periods.push(period)
      newTransforedForecastReports.targetOperatingResult.push({ x: period, y: forecastReport.targetOperatingResult })
      if (forecastReport.actualOperatingResult) {
        newTransforedForecastReports.actualOperatingResult.push({ x: period, y: forecastReport.actualOperatingResult })
        newTransforedForecastReports.delta.push({ x: period, y: forecastReport.actualOperatingResult - forecastReport.targetOperatingResult })
      }
    })

    const allValues = [].concat(
      newTransforedForecastReports.actualOperatingResult.map((coordinates: { x: string, y: string }) => coordinates.y),
      newTransforedForecastReports.targetOperatingResult.map((coordinates: { x: string, y: string }) => coordinates.y),
      newTransforedForecastReports.delta.map((coordinates: { x: string, y: string }) => coordinates.y)
    )

    const heightMax = Math.max(...allValues) * 1.1
    const heightMin = Math.min(...allValues) * 1.1

    setYAxisHeight({
      min: heightMin,
      max: heightMax,
    })

    const deltaColors = newTransforedForecastReports.delta.map((report: any) => (report.y > 0 ? 'green' : 'red'))

    setTransforedForecastReports({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      datasets: [
        {
          label: 'Target Operating Profit',
          type: 'line',
          fill: 'false',
          borderWidth: 1.8,
          data: newTransforedForecastReports.targetOperatingResult,
          backgroundColor: 'black',
          borderColor: 'black',
          order: 1,
          yAxisID: 'line-y-axis',
        },
        {
          label: 'Actual Operating Profit',
          type: 'line',
          data: newTransforedForecastReports.actualOperatingResult,
          fill: false,
          backgroundColor: '#00BF9A',
          borderColor: '#00BF9A',
          borderWidth: 1.8,
          order: 0,
          yAxisID: 'line-y-axis',
        },
      ],
      labels: newTransforedForecastReports.periods,
    })
  }, [forecastReports])

  useEffect(() => {
    if (!chartRef.current) return
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const myChartRef = chartRef.current.getContext('2d')

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof myLineChart !== 'undefined') myLineChart.destroy()

    const formatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
    })

    const stepSize = calcuculateAxisStepSize(yAxisHeight.min, yAxisHeight.max)
    const roundedYMax = Math.ceil(yAxisHeight.max / stepSize) * stepSize
    const roundedYMin = Math.floor(yAxisHeight.min / stepSize) * stepSize

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    myLineChart = new Chart(myChartRef, {
      type: 'line',
      data: transforedForecastReports,
      options: {
        tooltips: {
          mode: 'index',
          intersect: false,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          callbacks: { label: (tooltipItem, data) => `${data.datasets[tooltipItem.datasetIndex].label} ${formatter.format(tooltipItem.yLabel)}` },
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: false,
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: false,
              ticks: {
                min: roundedYMin,
                max: roundedYMax,
                stepSize,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                callback: (value) => formatter.format(value),
              },
              gridLines: {
                borderDash: [2, 2],
              },
            },
            {
              id: 'line-y-axis',
              stacked: false,
              display: false,
              ticks: {
                min: yAxisHeight.min,
                max: yAxisHeight.max,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                callback: (value) => formatter.format(value),
              },
            },
          ],
        },
      },
    })
  }, [transforedForecastReports])

  if (!transforedForecastReports) return <></>

  return (
    <canvas
      id="myChart"
      ref={chartRef}
    />
  )
}
