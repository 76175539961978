// Cannot be converted to typescript easily because this is a create-react-app
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App.tsx'
import { BrowserRouter } from 'react-router-dom'
import 'handsontable/dist/handsontable.full.css'

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)
