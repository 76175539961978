import React from 'react'
import { CssBaseline, Grid, makeStyles, Paper } from '@material-ui/core'
import InsufficientAccess from '../insufficient-access'
import SignInForm from '../sign-in-form'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    width: '50vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

interface Props {
  userHasSufficientAccess: boolean
}

const SignIn = ({
  userHasSufficientAccess
}: Props) => {
  const classes = useStyles()

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      <Grid item xs={12} sm={8} md={4} component={Paper} square>
        <Grid container alignItems="center" spacing={10} justify="center" className={classes.root}>
          <div className={classes.paper}>{!userHasSufficientAccess ? <InsufficientAccess /> : <SignInForm />}</div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SignIn
