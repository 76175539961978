import { FederatedSignInOptions } from '@aws-amplify/auth/src/types/Auth'
import { Auth } from 'aws-amplify'
import { Avatar, Button, Grid, Typography } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

const SignInForm = () => {
  const federatedSignInProvider = process.env.NODE_ENV === 'development' ? undefined : { provider: 'MakerStreetGoogleWorkplacesSso' }

  return (
    <>
      <Grid item>
        <Avatar>
          <LockOutlinedIcon />
        </Avatar>
      </Grid>
      <Grid item style={{ marginTop: 10 }}>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
      </Grid>
      <Grid item style={{ width: 300, marginTop: 40 }}>
        <Button fullWidth variant="contained" style={{ backgroundColor: '#fff' }} onClick={() => Auth.federatedSignIn(federatedSignInProvider as FederatedSignInOptions)}>
          <img alt="Google logo" style={{ marginRight: '24px', height: '24px' }} src="https://developers.google.com/identity/images/g-logo.png" />{' '}
          Sign in with Google
        </Button>
      </Grid>
    </>
  )
}

export default SignInForm
