const config = {
  Auth: {
    userPoolId: 'eu-central-1_sbiFIc2rS',
    userPoolWebClientId: '2o22vm7fe8s4rt7sc8ni468mhb',
    region: 'eu-central-1',
    mandatorySignIn: true,
    ssoServiceProvider: 'MakerStreetGoogleWorkplacesSso',
    oauth: {
      domain: 'makerstreet-acc.auth.eu-central-1.amazoncognito.com',
      redirectSignIn: 'https://acc.ms-insights.nl/',
      redirectSignOut: 'https://acc.ms-insights.nl/',
      scope: ['phone', 'email', 'openid', 'profile'],
      responseType: 'code',
    },
  },
  API: {
    aws_appsync_graphqlEndpoint: 'https://ukffgs2owjaormuwdmbwq65jbm.appsync-api.eu-central-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-central-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
}

export default config
