/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const removeUserFromGroup = /* GraphQL */ `
  mutation RemoveUserFromGroup($input: UserGroupsInput) {
    removeUserFromGroup(input: $input)
  }
`
export const addUserToGroup = /* GraphQL */ `
  mutation AddUserToGroup($input: UserGroupsInput) {
    addUserToGroup(input: $input)
  }
`
export const updateProfitLossPlan = /* GraphQL */ `
  mutation UpdateProfitLossPlan($id: ID!, $input: ProfitLossInput!) {
    updateProfitLossPlan(id: $id, input: $input) {
      id
      period
      grossProfit
      internalCosts
      partnerFee
      personnelCosts
      advertisingSellingCosts
      additionalCosts
      operatingExpenses
      operatingResult
      operatingResultAfterTax
    }
  }
`
export const updateProfitLossActual = /* GraphQL */ `
  mutation UpdateProfitLossActual($id: ID!, $input: ProfitLossInput!) {
    updateProfitLossActual(id: $id, input: $input) {
      id
      period
      grossProfit
      internalCosts
      partnerFee
      personnelCosts
      advertisingSellingCosts
      additionalCosts
      operatingExpenses
      operatingResult
      operatingResultAfterTax
    }
  }
`
