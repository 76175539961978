import { useEffect, createRef, useState, RefObject } from 'react'
import Chart, { Chart as IChart } from 'chart.js'
import * as queries from 'src/api/graphql/queries'
import { API } from 'aws-amplify'
import { format } from 'date-fns'
import { ForecastReport } from 'src/api/types'
import { Period } from 'src/components/date-picker'
import { calcuculateAxisStepSize } from 'src/utils/calculate-axis-step-size'

type TChart = typeof IChart

let myLineChart: TChart | undefined

interface Props {
  companies: Array<any>
  period: Period
}

const ForecastChart = ({
  companies,
  period,
}: Props) => {
  const [forecastReports, setForecastReports] = useState<ForecastReport[]>([])
  const [transforedForecastReports, setTransforedForecastReports] = useState()
  const [yAxisHeight, setYAxisHeight] = useState(0)
  const chartRef: RefObject<any> = createRef()

  useEffect(() => {
    if (!companies || companies.length < 1 || !period) return

    (async () => {
      try {
        const apiResult = await (API.graphql({
          query: queries.getForecastKpiReport,
          variables: {
            input: {
              companyIds: companies,
              startDate: format(new Date(period.startDate), 'yyyy-MM-dd'),
              endDate: format(new Date(period.endDate), 'yyyy-MM-dd'),
            },
          },
        })) as { data: { getForecastKpiReport: ForecastReport[] } }
        setForecastReports(apiResult.data.getForecastKpiReport)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [companies, period])

  useEffect(() => {
    if (!forecastReports) return

    const newTransforedForecastReports: {
      [key: string]: any
    } = {
      billableScheduledBudget: [],
      margin: [],
      reservationsScheduledBudget: [],
      availableBudget: [],
      nonBillableScheduledBudget: [],
      internalScheduledBudget: [],
      scheduledTimeOffBudget: [],
      grossProfitTarget: [],
      actualOperatingCosts: [],
      actualOperatingCostsPrognosed: [],
      targetOperatingCosts: [],
      actualGrossProfit: [],
      periods: [],
    }

    let proposedYAxisHeight = 0
    forecastReports.forEach((forecastReport) => {
      const period = forecastReport?.period && format(new Date(forecastReport?.period), 'LLL yy')
      newTransforedForecastReports.periods.push(period)
      newTransforedForecastReports.billableScheduledBudget.push({ x: period, y: forecastReport.billableScheduledBudget })
      newTransforedForecastReports.margin.push({ x: period, y: forecastReport.margin })
      newTransforedForecastReports.reservationsScheduledBudget.push({ x: period, y: forecastReport.reservationsScheduledBudget })
      newTransforedForecastReports.availableBudget.push({ x: period, y: (forecastReport?.availableBudget || 0) - (forecastReport?.scheduledBudget || 0) })
      newTransforedForecastReports.nonBillableScheduledBudget.push({ x: period, y: forecastReport.nonBillableScheduledBudget })
      newTransforedForecastReports.internalScheduledBudget.push({ x: period, y: forecastReport.internalScheduledBudget })
      newTransforedForecastReports.scheduledTimeOffBudget.push({ x: period, y: forecastReport.scheduledTimeOffBudget })
      newTransforedForecastReports.grossProfitTarget.push({ x: period, y: forecastReport.grossProfitTarget })
      newTransforedForecastReports.actualOperatingCosts.push({ x: period, y: forecastReport.actualOperatingCosts })
      newTransforedForecastReports.actualOperatingCostsPrognosed.push({ x: period, y: forecastReport.actualOperatingCostsPrognosed })
      newTransforedForecastReports.targetOperatingCosts.push({ x: period, y: forecastReport.targetOperatingCosts })
      newTransforedForecastReports.actualGrossProfit.push({ x: period, y: forecastReport.actualGrossProfit })

      const barHeight =
        (forecastReport?.billableScheduledBudget || 0) +
        (forecastReport?.margin || 0) +
        (forecastReport?.reservationsScheduledBudget || 0) +
        (forecastReport?.availableBudget || 0) -
        (forecastReport?.scheduledBudget || 0) +
        (forecastReport?.nonBillableScheduledBudget || 0) +
        (forecastReport?.internalScheduledBudget || 0) +
        (forecastReport?.scheduledTimeOffBudget || 0)
      proposedYAxisHeight = Math.max.apply(Math, [
        proposedYAxisHeight,
        barHeight,
        forecastReport.grossProfitTarget,
        forecastReport.actualOperatingCosts,
      ])
    })

    setYAxisHeight(Math.round(proposedYAxisHeight * 1.1))
    const barPercentage = 0.5
    setTransforedForecastReports({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      datasets: [
        {
          label: 'Target',
          type: 'line',
          fill: false,
          borderColor: 'black',
          borderWidth: 1.8,
          data: newTransforedForecastReports.grossProfitTarget,
          backgroundColor: 'black',
          order: 0,
          yAxisID: 'line-y-axis',
        },
        {
          label: 'Cost level',
          type: 'line',
          data: newTransforedForecastReports.actualOperatingCosts,
          fill: false,
          borderColor: 'red',
          backgroundColor: 'red',
          borderWidth: 1.8,
          order: 1,
          yAxisID: 'line-y-axis',
        },
        {
          label: 'Cost level target',
          type: 'line',
          data: newTransforedForecastReports.targetOperatingCosts,
          fill: false,
          borderColor: 'orange',
          backgroundColor: 'orange',
          borderWidth: 1.5,
          order: 1,
          yAxisID: 'line-y-axis',
          hidden: true
        },
        {
          label: 'Cost level prognosed',
          type: 'line',
          data: newTransforedForecastReports.actualOperatingCostsPrognosed,
          fill: false,
          borderColor: 'red',
          backgroundColor: 'red',
          borderWidth: 1.5,
          order: 1,
          yAxisID: 'line-y-axis',
          borderDash: [5,5],
        },
        {
          label: 'Bruto winst',
          type: 'line',
          data: newTransforedForecastReports.actualGrossProfit,
          fill: false,
          borderColor: 'green',
          backgroundColor: 'green',
          borderWidth: 1.5,
          order: 2,
          yAxisID: 'line-y-axis',
        },
        {
          label: 'Billable',
          data: newTransforedForecastReports.billableScheduledBudget,
          backgroundColor: 'rgb(21, 101, 192)',
          barPercentage,
          order: 3,
        },
        {
          label: 'Margin',
          data: newTransforedForecastReports.margin,
          backgroundColor: 'rgb(30, 136, 229)',
          barPercentage,
          order: 4,
        },
        {
          label: 'Reservations',
          data: newTransforedForecastReports.reservationsScheduledBudget,
          backgroundColor: 'rgb(100, 181, 246)',
          barPercentage,
          order: 5,
        },
        {
          label: 'Non billable',
          data: newTransforedForecastReports.nonBillableScheduledBudget,
          backgroundColor: 'rgb(255, 143, 0)',
          barPercentage,
          order: 6,
        },
        {
          label: 'Available',
          data: newTransforedForecastReports.availableBudget,
          backgroundColor: 'rgb(244, 67, 54)',
          barPercentage,
          order: 7,
        },
        {
          label: 'Internal',
          data: newTransforedForecastReports.internalScheduledBudget,
          backgroundColor: '#C5C5C5',
          barPercentage,
          order: 8,
        },
        {
          label: 'Time off',
          data: newTransforedForecastReports.scheduledTimeOffBudget,
          backgroundColor: '#e5e5e5',
          barPercentage,
          order: 9,
        },
      ],
      labels: newTransforedForecastReports.periods,
    })
  }, [forecastReports])

  useEffect(() => {
    if (!chartRef.current) return
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const myChartRef = chartRef.current.getContext('2d')

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof myLineChart !== 'undefined') myLineChart.destroy()

    const formatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
    })

    const stepSize = calcuculateAxisStepSize(0, yAxisHeight)
    const roundedYMax = Math.floor(yAxisHeight / stepSize) * stepSize

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    myLineChart = new Chart(myChartRef, {
      type: 'bar',
      data: transforedForecastReports,
      options: {
        tooltips: {
          mode: 'index',
          intersect: false,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          callbacks: { label: (tooltipItem, data) => `${data.datasets[tooltipItem.datasetIndex].label} ${formatter.format(tooltipItem.yLabel)}` },
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                min: 0,
                max: roundedYMax,
                stepSize,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                callback: (value) => formatter.format(value),
              },
              gridLines: {
                borderDash: [2, 2],
              },
            },
            {
              id: 'line-y-axis',
              stacked: false,
              display: false,
              ticks: {
                min: 0,
                max: yAxisHeight,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                callback: (value) => formatter.format(value),
              },
            },
          ],
        },
      },
    })
  }, [transforedForecastReports])

  if (!transforedForecastReports) return <></>

  return (
    <canvas
      id="myChart"
      ref={chartRef}
    />
  )
}

export default ForecastChart
