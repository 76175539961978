/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUsers = /* GraphQL */ `
  query GetUsers {
    getUsers {
      id
      email
      groups {
        name
        description
      }
    }
  }
`
export const getUserGroups = /* GraphQL */ `
  query GetUserGroups {
    getUserGroups {
      name
      description
    }
  }
`
export const getCompanies = /* GraphQL */ `
  query GetCompanies {
    getCompanies {
      id
      name
      holdingId
      productiveUnitId
    }
  }
`
export const getProfitLossPlan = /* GraphQL */ `
  query GetProfitLossPlan($id: ID!, $startDate: String!, $endDate: String!) {
    getProfitLossPlan(id: $id, startDate: $startDate, endDate: $endDate) {
      id
      period
      grossProfit
      internalCosts
      partnerFee
      personnelCosts
      advertisingSellingCosts
      additionalCosts
      operatingExpenses
      operatingResult
      operatingResultAfterTax
    }
  }
`
export const getProfitLossActual = /* GraphQL */ `
  query GetProfitLossActual($id: ID!, $startDate: String!, $endDate: String!) {
    getProfitLossActual(id: $id, startDate: $startDate, endDate: $endDate) {
      id
      period
      grossProfit
      internalCosts
      partnerFee
      personnelCosts
      advertisingSellingCosts
      additionalCosts
      operatingExpenses
      operatingResult
      operatingResultAfterTax
    }
  }
`
export const getForecastKpiReport = /* GraphQL */ `
  query GetForecastKpiReport($input: GetForecastKpiReport) {
    getForecastKpiReport(input: $input) {
      period
      availableBudget
      scheduledBudget
      internalScheduledBudget
      billableScheduledBudget
      reservationsScheduledBudget
      nonBillableScheduledBudget
      scheduledTimeOffBudget
      margin
      grossProfitTarget
      actualOperatingCosts
      actualOperatingCostsPrognosed
      actualGrossProfit
      targetOperatingCosts
      targetOperatingResult
      actualOperatingResult
      updatedAt
    }
  }
`
export const getSignedUploadUrl = /* GraphQL */ `
  query GetSignedUploadUrl($fileName: String!) {
    getSignedUploadUrl(fileName: $fileName) {
      url
    }
  }
`
