export const calcuculateAxisStepSize = (minValue: number, maxValue: number) => {
  const amountGridLines = 17
  const totalValue = +minValue + maxValue
  let stepSize = 5000

  while (amountGridLines < (totalValue / stepSize)) {
    stepSize = stepSize += 5000
  }

  return stepSize
}
