import React, { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { Avatar, Button, Grid, Typography } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

const InsufficientAccess = () => {
  useEffect(() => {
    setTimeout(() => {
      Auth.signOut()
    }, 6000)
  }, [])

  return (
    <>
      <Grid item>
        <Avatar>
          <LockOutlinedIcon />
        </Avatar>
      </Grid>
      <Grid item style={{ marginTop: 10 }}>
        <Typography component="h1" variant="h5">
          Insufficient access
        </Typography>
        <Typography component="h1" variant="body1">
          Thanks for signing in! Though it seems you do not have the right permissions to access this application. If you would like access to this
          application please get in touch with your administrator.
        </Typography>
        <Typography component="h1" variant="caption">
          Within 60 seconds you will be signed out.
        </Typography>
      </Grid>
      <Grid item style={{ width: 300, marginTop: 40 }}>
        <Button fullWidth variant="contained" style={{ backgroundColor: '#fff' }} onClick={() => Auth.signOut()}>
          Signout
        </Button>
      </Grid>
    </>
  )
}

export default InsufficientAccess
