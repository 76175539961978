import React, { useEffect, useState } from 'react'
import { RouterProps, useHistory } from 'react-router-dom'
import { Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import { API } from 'aws-amplify'
import { User, Users } from 'src/domains/user/types'
import * as queries from '../../api/graphql/queries'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}))

const UsersList = () => {
  const classes = useStyles()
  const history = useHistory()
  const [users, setUsers] = useState<Array<User>>([])

  useEffect(() => {
    (async () => {
      try {
        const apiResponse: any = await API.graphql({ query: queries.getUsers })
        const users: Users = apiResponse.data.getUsers
        users.sort((a, b) => (a.email > b.email ? 1 : b.email > a.email ? -1 : 0))
        setUsers(apiResponse.data.getUsers)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  if (!users) return <></>

  const columns = [
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'hasAccess', headerName: 'Has Access', width: 130 },
    { field: 'canEdit', headerName: 'Editor', width: 100 },
    { field: 'isAccountant', headerName: 'Accountant', width: 130 },
    { field: 'isAdmin', headerName: 'Administrator', width: 140 },
    { field: 'groups', headerName: 'Company access', width: 550 },
  ]

  const rows = users.map((user) => ({
    id: user.id,
    email: user.email,
    hasAccess: user.groups.find((group) => group.name === 'Readers') ? 'yes' : '',
    canEdit: user.groups.find((group) => group.name === 'Editors') ? 'yes' : '',
    isAdmin: user.groups.find((group) => group.name === 'Administrators') ? 'yes' : '',
    isAccountant: user.groups.find((group) => group.name === 'Accountants') ? 'yes' : '',
    groups: user.groups.filter((group) => !(group.name === 'Administrators' || group.name === 'Readers')).map((group) => ` ${group.description}`),
  }))

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={2} justify="space-between" direction="row">
        <Grid item>
          <Typography component="h1" variant="h3" color="inherit" noWrap>
            Users
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper} style={{ height: 740 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={20}
              disableMultipleSelection={true}
              onRowClick={(event) => history.push(`/users/detail/${event.row.id}`, users)}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default UsersList
