import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import clsx from 'clsx'
import { Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import { Business as BusinessIcon, ChevronLeft as ChevronLeftIcon, Dashboard as DashboardIcon, People as UsersIcon, Publish as PublishIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
}))

interface Props {
  handleDrawerClose: () => void
  open: boolean
}

const Menu = ({ handleDrawerClose, open }: Props) => {
  const classes = useStyles()
  const [userGroups, setUserGroups] = useState<Array<string>>([])

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser()
      setUserGroups(user.signInUserSession.accessToken.payload['cognito:groups'])
    })()
  }, [])

  if (!userGroups.length) return null

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem button component={Link} to="/">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        {userGroups.includes('Editors') && (
          <ListItem button component={Link} to="/companies">
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Companies" />
          </ListItem>
        )}
        {userGroups.includes('Administrators') && (
          <ListItem button component={Link} to="/users">
            <ListItemIcon>
              <UsersIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        )}
        {userGroups.includes('Accountants') && (
            <ListItem button component={Link} to="/uploadPnl">
              <ListItemIcon>
                <PublishIcon />
              </ListItemIcon>
              <ListItemText primary="Accountants" />
            </ListItem>
        )}
      </List>
    </Drawer>
  )
}

export default Menu
