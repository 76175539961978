import clsx from 'clsx'
import React, { useState } from 'react'
import { Container, Grid, makeStyles, Paper } from '@material-ui/core'
import ProfitLossChart from 'src/components/profit-loss-chart'
import Chart from 'src/components/chart'
import CompanySelect from 'src/components/company-select'
import DatePicker, { Period } from 'src/components/date-picker'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    padding: theme.spacing(1),
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
  },
  paperChart: {
    height: '100vw',
    [theme.breakpoints.up('sm')]: {
      height: '100%',
      minHeight: '600px'
    },
  }
}))

export interface ForecastReportProps {
  companies: Companies
}

const ForecastReport = ({
  companies
}: ForecastReportProps) => {
  const classes = useStyles()
  const [selectedCompanies, setSelectedCompanies] = useState<Array<string>>()
  const [selectedPeriod, setSelectedPeriod] = useState<Period>()

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={8}>
                <DatePicker updatePeriod={setSelectedPeriod} />
              </Grid>
              <Grid item xs={12} md={4}>
                <CompanySelect updateCompanySelection={setSelectedCompanies} companies={companies} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={clsx(classes.paper, classes.paperChart)}>
            <Chart
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              companies={selectedCompanies}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              period={selectedPeriod}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={clsx(classes.paper, classes.paperChart)}>
            <ProfitLossChart
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              companies={selectedCompanies}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              period={selectedPeriod}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ForecastReport
