import React from 'react'
import { Container, Grid, Link, makeStyles, Paper, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import ProfitLossTables from '../profit-loss-tables'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}))

export interface CompanyDetailProps {
  companies: Companies
}

const CompanyDetail = ({
  companies,
}: CompanyDetailProps) => {
  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  if (!companies) return null

  const company = companies.find((company) => company.id === id)

  if (!company) return null

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={2} justify="space-between" direction="row" alignItems="center">
        <Grid item>
          <Typography component="h1" variant="h3" color="inherit" noWrap>
            {company.name}
          </Typography>
        </Grid>
        <Grid item>
          <Link
            color="primary"
            onClick={() => history.goBack()}
          >
            Back
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <ProfitLossTables />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default CompanyDetail
