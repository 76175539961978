import React, { useEffect, useState } from 'react'
import { RouterProps, useHistory, useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { Container, Grid, Link, makeStyles, Paper, Typography, Switch, FormGroup, FormControlLabel } from '@material-ui/core'
import { User, UserGroup, UserGroupName, UserGroups, Users } from 'src/domains/user/types'
import * as queries from '../../api/graphql/queries'
import * as mutations from '../../api/graphql/mutations'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}))

const UserDetail = (props: RouterProps) => {
  const classes = useStyles()
  const historyRouter = useHistory()
  const { id } = useParams<{ id: string }>()
  const users = props.history.location.state as Users
  const [user, setUser] = useState<User>()
  const [filteredUserGroups, setFilteredUserGroups] = useState<UserGroups>([])
  const [companySwitches, setCompanySwitches] = useState([])
  const [access, setAccess] = useState()

  useEffect(() => {
    (async () => {
      try {
        const apiResponse: any = await API.graphql({ query: queries.getUserGroups })
        const userGroups: UserGroups = apiResponse.data.getUserGroups
        userGroups.sort((a, b) => (a.description > b.description ? 1 : b.description > a.description ? -1 : 0))
        setFilteredUserGroups(userGroups.filter((group) => !(group.name === 'Administrators' || group.name === 'Readers')))
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    if (!users || !filteredUserGroups) return
    const user = users.find((user: User) => user.id === id)
    if (user === undefined) return
    const switchState: Array<UserGroupName> = []
    filteredUserGroups.forEach((userGroup: UserGroup) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      switchState[userGroup.name] = false
    })
    user.groups.forEach((userGroup: UserGroup) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      switchState[userGroup.name] = true
    })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    switchState['Administrators'] = !!user.groups.find(({ name }) => name === 'Administrators')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    switchState['Readers'] = !!user.groups.find(({ name }) => name === 'Readers')
    setUser(user)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setAccess({ ...switchState })
  }, [users, filteredUserGroups])

  useEffect(() => {
    if (!access || !filteredUserGroups) return
    const companySwitchElements: ((prevState: never[]) => never[]) | JSX.Element[] = []
    filteredUserGroups.forEach(({ name, description }) => {
      companySwitchElements.push(
        <FormGroup row key={name}>
          <FormControlLabel
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            control={<Switch checked={access[name]} onChange={handleSwitchChange} name={name} />}
            label={description}
          />
        </FormGroup>
      )
    })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setCompanySwitches(companySwitchElements)
  }, [access, filteredUserGroups])

  const handleSwitchChange = (event: { target: { checked: any; name: any } }) => {
    (async () => {
      try {
        await API.graphql({
          query: event.target.checked ? mutations.addUserToGroup : mutations.removeUserFromGroup,
          variables: {
            input: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              userId: user.id,
              groupName: event.target.name,
            },
          },
        })
      } catch (error) {
        console.log(error)
      }
    })()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setAccess({ ...access, [event.target.name]: event.target.checked })
  }

  if (!user || !companySwitches || !access) return null

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={2} justify="space-between" direction="row">
        <Grid item>
          <Typography component="h1" variant="h3" color="inherit" noWrap>
            {user.email}
          </Typography>
        </Grid>
        <Grid item>
          <Link
            color="primary"
            onClick={() => {
              historyRouter.goBack()
            }}
          >
            Back
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="h1" variant="h5" color="inherit" noWrap>
                  Edit access
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                  Application access
                </Typography>
                <FormGroup row>
                  <FormControlLabel
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    control={<Switch checked={access.Readers} onChange={handleSwitchChange} name="Readers" />}
                    label="Has Access"
                  />
                </FormGroup>
                <FormGroup row>
                  <FormControlLabel
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    control={<Switch checked={access['Administrators']} onChange={handleSwitchChange} name="Administrators" />}
                    label="Is Administrator"
                  />
                </FormGroup>
                <FormGroup row>
                  <FormControlLabel
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      control={<Switch checked={access['Editors']} onChange={handleSwitchChange} name="Editors" />}
                      label="Is Editor"
                  />
                </FormGroup>
                <FormGroup row>
                  <FormControlLabel
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      control={<Switch checked={access['Accountants']} onChange={handleSwitchChange} name="Accountants" />}
                      label="Is Accountant"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                  Company access
                </Typography>
                {companySwitches}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default UserDetail
