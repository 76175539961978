import React from 'react'
import clsx from 'clsx'
import { AmplifySignOut } from '@aws-amplify/ui-react'
import { AppBar, IconButton, makeStyles, Toolbar, Typography, Grid } from '@material-ui/core'

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  logoText: {
    display: 'block',
    marginLeft: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}))

interface Props {
  handleDrawerOpen: () => void
  open: boolean
}

const Header = ({
  handleDrawerOpen,
  open,
}: Props) => {
  const classes = useStyles()

  return (
    <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
      <Toolbar>
        <Grid container justify="space-between">
          <Typography component="h1" variant="h3" color="inherit" noWrap className={classes.logo}>
            <img
              onClick={handleDrawerOpen}
              width="40px"
              height="40px"
              alt="MakerStreet logo"
              src="https://www.makerstreet.nl/static/images/logos/logo--makerstreet-light.svg"
            />{' '}
            <span className={classes.logoText}>
              Cockpit
            </span>
          </Typography>
          <IconButton color="inherit" style={{ padding: '4px' }}>
            <AmplifySignOut />
          </IconButton>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default Header
