import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}))

export interface CompaniesListProps {
  companies: Companies
}

const CompaniesList = ({
  companies
}: CompaniesListProps) => {
  const classes = useStyles()
  useRouteMatch()
  const history = useHistory()

  if (!companies) return <></>

  const columns = [
    { field: 'name', headerName: 'Name', width: 300 },
    { field: 'productiveUnitId', headerName: 'Productive ID', width: 150 },
    { field: 'id', headerName: 'ID', width: 400 },
  ]

  const rows = companies.map((company) => ({
    id: company.id,
    name: company.name,
    productiveUnitId: company.productiveUnitId,
  }))

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={2} justify="space-between" direction="row">
        <Grid item>
          <Typography component="h1" variant="h3" color="inherit" noWrap>
            Companies
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper} style={{ height: 740 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={20}
              disableMultipleSelection={true}
              onRowClick={(event) => history.push(`/companies/detail/${event.row.id}`)}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default CompaniesList
