import { makeStyles } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import 'date-fns'
import { startOfYear, endOfYear, getMonth, subMonths, addMonths } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import Grid from '@material-ui/core/Grid'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

const useStyles = makeStyles(() => ({
  picker: {
    width: '100%'
  },
}))

interface Props {
  updatePeriod: (period: Period) => void
}

export type Period = {
  startDate: Date | number
  endDate: Date | number
}

const DateRangePickerComponent = ({
  updatePeriod
}: Props) => {
  const currentDate = new Date()

  const currentMonth = getMonth(currentDate)
  let defaultStartDate = startOfYear(currentDate)
  let defaultEndDate = endOfYear(currentDate)

  if (currentMonth < 3) {
    defaultStartDate = subMonths(defaultStartDate, 3)
  }
  if (currentMonth > 8) {
    defaultEndDate = addMonths(defaultEndDate, 3)
  }

  const [startDate, setStartDate] = useState<Date | number>(defaultStartDate)
  const [endDate, setEndDate] = useState<Date | number>(defaultEndDate)
  const classes = useStyles();

  useEffect(() => {
    updatePeriod({ startDate, endDate })
  }, [startDate, endDate])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <KeyboardDatePicker
            className={classes.picker}
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="start-date-picker"
            value={startDate}
            onChange={(date) => setStartDate(date as Date | number)}
            label="Van"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <KeyboardDatePicker
            className={classes.picker}
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="end-date-picker"
            value={endDate}
            onChange={(date) => setEndDate(date as Date | number)}
            label="Tot"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default DateRangePickerComponent
