import Handsontable from 'handsontable'
import React, { createRef, LegacyRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { HotTable } from '@handsontable/react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { API, graphqlOperation } from 'aws-amplify'
import { addYears, endOfYear, format, startOfYear, subYears } from 'date-fns'
import { generateTableSettings, findJournalPostIndex } from 'src/components/profit-loss-tables/helpers'
import * as mutations from '../../api/graphql/mutations'
import * as queries from '../../api/graphql/queries'

const useStyles = makeStyles(() => ({
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  },
}))

export type profitLossData = Array<{
  advertisingSellingCosts: number
  grossProfit: number
  id: string
  operatingExpenses: number
  operatingResult: number
  period: string
  personnelCosts: number
}>

const CompanyProfitLossTables = () => {
  const [profitLossPlanData, setProfitLossPlanData] = useState<profitLossData>([])
  const [profitLossPlanTableSettings, setProfitLossPlanTableSettings] = useState<Handsontable.GridSettings>({licenseKey: 'non-commercial-and-evaluation'})

  const [profitLossActualData, setProfitLossActualData] = useState<profitLossData>([])
  const [profitLossActualTableSettings, setProfitLossActualTableSettings] = useState<Handsontable.GridSettings>({licenseKey: 'non-commercial-and-evaluation'})

  const [year, setYear] = useState(startOfYear(new Date()))
  const { id } = useParams<{ id: string }>()

  const profitLossPlanTableRef: LegacyRef<HotTable> = createRef()
  const profitLossActualTableRef: LegacyRef<HotTable> = createRef()

  const classes = useStyles()

  const handleNextYear = () => setYear(addYears(year, 1))
  const handlePreviousYear = () => setYear(subYears(year, 1))

  const profitLossDataRowOrder = [
    'grossProfit',
    'internalCosts',
    'partnerFee',
    'personnelCosts',
    'advertisingSellingCosts',
    'additionalCosts',
    'operatingExpenses',
    'operatingResult',
    'operatingResultAfterTax',
  ]

  useEffect(() => {
    setProfitLossPlanTableSettings(generateTableSettings(profitLossPlanData, year))
    setProfitLossActualTableSettings(generateTableSettings(profitLossActualData, year))
  }, [profitLossPlanData, profitLossActualData, year])

  useEffect(() => {
    const getProfitLossStatements = async () => {
      try {
        const apiResponseProfitLossPlan: any = await API.graphql({
          query: queries.getProfitLossPlan,
          variables: {
            id: id,
            startDate: format(year, 'yyyy-MM-dd'),
            endDate: format(endOfYear(year), 'yyyy-MM-dd')
          },
        })
        setProfitLossPlanData(apiResponseProfitLossPlan.data.getProfitLossPlan)

        const apiResponseProfitLossActual: any = await API.graphql({
          query: queries.getProfitLossActual,
          variables: {
            id: id,
            startDate: format(year, 'yyyy-MM-dd'),
            endDate: format(endOfYear(year), 'yyyy-MM-dd')
          },
        })
        setProfitLossActualData(apiResponseProfitLossActual.data.getProfitLossActual)
      } catch (error) {
        console.log(error)
      }
    }
    getProfitLossStatements()
  }, [id, year])

  const updateRow = (event: any, tableRef: any, method: any) => {
    if (!event) return
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    event.forEach((update) => {
      const updatedColumn = tableRef.current.hotInstance.getDataAtProp(update[1])
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updatedColumn.forEach((value, index) => {
        if (typeof value === 'string') {
          if (value.includes('.') || value.includes(',')) {
            updatedColumn[index] = parseFloat(value.replace('.', '').replace(',', '.'))
          }
        }
      })

      const updatedRowObjects = {}

      for (const key of profitLossDataRowOrder) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updatedRowObjects[key] = updatedColumn[findJournalPostIndex(key)]
      }

      const updatedRow = {
        period: update[1],
        ...updatedRowObjects,
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      API.graphql(graphqlOperation(mutations[method], { id: id, input: updatedRow }))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .catch((error) => {
          console.log(error)
        })
    })
  }

  if (!profitLossPlanTableSettings) return null

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.buttonGroup}>
          <Button
            onClick={handlePreviousYear}
            variant="outlined"
            color="primary"
          >
            Previous year
          </Button>
          <Button
            onClick={handleNextYear}
            variant="outlined"
            color="primary"
          >
            Next year
          </Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography component="h1" variant="h5" color="inherit" noWrap>
          P&#38;L Plan
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <HotTable
          ref={profitLossPlanTableRef}
          settings={profitLossPlanTableSettings}
          afterChange={(changes) => updateRow(changes, profitLossPlanTableRef, 'updateProfitLossPlan')}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component="h1" variant="h5" color="inherit" noWrap>
          P&#38;L actual
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <HotTable
          ref={profitLossActualTableRef}
          settings={profitLossActualTableSettings}
          afterChange={(changes) => updateRow(changes, profitLossActualTableRef, 'updateProfitLossActual')}
        />
      </Grid>
    </Grid>
  )
}

export default CompanyProfitLossTables
