import React, { useEffect, useState } from 'react'
import { Route, RouterProps, Switch } from 'react-router-dom'
import { API } from 'aws-amplify'
import { makeStyles } from '@material-ui/core'
import UserDetail from 'src/components/user-detail'
import * as queries from 'src/api/graphql/queries'
import CompanyDetail, { CompanyDetailProps } from 'src/components/companies-detail'
import CompaniesList, { CompaniesListProps } from 'src/components/companies-list'
import UsersList from '../../components/user-list'
import ForecastReport, { ForecastReportProps } from 'src/components/forecast-report'
import UploadPnl from "../../components/upload-pnl";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
}))

const MainView = () => {
  const classes = useStyles()
  const [companies, setCompanies] = useState<Companies>([])

  useEffect(() => {
    (async () => {
      try {
        const apiResponse: any = await API.graphql({ query: queries.getCompanies })
        const companies: Companies = apiResponse.data.getCompanies
        companies.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
        setCompanies(companies)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Switch>
        <Route exact path={['/', '/dashboard']} component={(props: ForecastReportProps) => <ForecastReport {...props} companies={companies} />} />
        <Route exact path="/companies" component={(props: CompaniesListProps) => <CompaniesList {...props} companies={companies} />} />
        <Route exact path="/companies/detail/:id" component={(props: CompanyDetailProps) => <CompanyDetail {...props} companies={companies} />} />
        <Route exact path="/users" component={() => <UsersList />} />
        <Route exact path="/users/detail/:id" component={(props: RouterProps) => <UserDetail {...props} />} />
        <Route exact path="/uploadPnl" component={(props: RouterProps) => <UploadPnl {...props} />} />
      </Switch>
      <div />
    </main>
  )
}

export default MainView
