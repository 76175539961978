import { CssBaseline, makeStyles } from '@material-ui/core'
import { CognitoUser } from 'amazon-cognito-identity-js'
import Amplify, { Auth, Hub } from 'aws-amplify'
import React, { useState, useEffect } from 'react'
import awsconfig from './aws-exports'
import MainView from './views/main'
import Header from './components/header'
import Menu from './components/menu'
import SignIn from './components/sign-in'

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}))

const App = () => {
  const classes = useStyles()
  const [user, setUser] = useState<CognitoUser>()
  const [userHasSufficientAccess, setUserHasSufficientAccess] = useState(true)
  const [open, setOpen] = useState(false)
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const validateUserHasSufficientAccess = (user: CognitoUser) => {
    if (user) {
      const userSession = user.getSignInUserSession()
      const userGroups = userSession && userSession.getAccessToken().payload['cognito:groups']
      setUserHasSufficientAccess(
        !!(
          userGroups &&
          userGroups.includes('Readers') &&
          userGroups.filter((userGroup: string) => !(userGroup === 'Administrators')).length > 1)
      )
    }
  }

  useEffect(() => {
    const updateUser = async () => {
      try {
        const user: CognitoUser = await Auth.currentAuthenticatedUser()
        setUser(user)
        validateUserHasSufficientAccess(user)
      } catch {
        setUser(undefined)
      }
    }
    Hub.listen('auth', updateUser)
    updateUser()
    return () => Hub.remove('auth', updateUser)
  }, [])

  return (
    <>
      {!user || !userHasSufficientAccess ? (
        <SignIn userHasSufficientAccess={userHasSufficientAccess} />
      ) : (
        <div className={classes.root}>
          <CssBaseline />
          <Header handleDrawerOpen={handleDrawerOpen} open={open} />
          <Menu handleDrawerClose={handleDrawerClose} open={open} />
          <MainView />
        </div>
      )}
    </>
  )
}

export default App
